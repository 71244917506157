<template>
  <div id="login">
    <div class="login-form">
      <div style="text-align: center">
        <h2 style="margin-top: 0">Login</h2>
      </div>
      <md-field>
        <label>E-Mail-Adresse</label>
        <md-input v-model="email" />
      </md-field>
      <md-field>
        <label>Passwort</label>
        <md-input type="password" v-model="password" />
      </md-field>
      <md-button
        class="md-primary md-raised"
        style="margin: 0; width: 100%"
        @click="login"
      >
        Anmelden
      </md-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URI_BASE } from "../config";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async login() {
      try {
        let formData = new FormData();
        formData.set("username", this.email);
        formData.set("password", this.password);

        let config = {
          method: "POST",
          url: `${API_URI_BASE}/auth/login`,
          data: formData,
          withCredentials: true,
          crossDomain: true,
        };

        let response = await axios(config);

        localStorage.setItem("userid", response.data.user_id);

        await this.$router.push({ name: "Dashboard" });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/main";

#login {
  background-color: #f4f4f4;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  padding: 48px 32px;
  background-color: white;
  border-radius: $radius;
  border: 1px solid $dark-blue;
  width: 500px;
}
</style>
